$primary-color: #053364; // Основной цвет для кнопок и других элементов
$hover-color: #0056b3; // Цвет при наведении

.container {
  padding: 2rem;
  text-align: center;
  margin-top: 10vh;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.mainBtn {
  background-color: $primary-color;
  color: white;
  padding: 20px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;

  &:hover {
    background-color: $hover-color;
    transform: scale(1.05);
  }
}

.activeBtn {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border: 2px solid #0056b3;
}

.contentContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
