.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.dropdownGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: black;
}

.dropdown {
  padding: 0.8rem 1rem;
  font-size: 1.1rem;
  border: 2px solid #003b6f;
  border-radius: 12px;
  color: black;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    border-color: #002b50;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}

.tableContainer {
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tableTitle {
  margin-bottom: 15px;
  color: #333;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.tableHeader {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  background-color: #003b6f;
  color: white;
}

.tableRow {
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.tableCell {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }
}