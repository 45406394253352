$primary-color: #003b6f; // Основной синий
$secondary-color: #ffffff; // Лёгкий голубой
$highlight-color: #1e8fff91; // Для акцентов
$border-color: #000000; // Полупрозрачный синий
$text-color: #333;

.container {
  padding: 2.5rem;
  background: linear-gradient(145deg, $secondary-color, #ffffff);
  border-radius: 16px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: $text-color; // черный
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;

  .label {
    font-size: 1.2rem;
    font-weight: 600;
    color: $text-color; // черный
    margin-bottom: 0.5rem;
  }

  .dropdown {
    padding: 0.8rem 1rem;
    font-size: 1.1rem;
    border: 2px solid $border-color;
    border-radius: 12px;
    color: $text-color; // черный
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:focus,
    &:hover {
      border-color: $primary-color;
      box-shadow: 0 0 6px rgba(0, 59, 111, 0.5);
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tableHeader {
  text-align: left;
  padding: 1rem;
  border: 1px solid $border-color;
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
}

.tableRow {
  &:nth-child(even) {
    background-color: lighten($secondary-color, 5%);
  }
}

.tableCell {
  text-align: left;
  padding: 1rem;
  border: 1px solid $border-color;
  color: $text-color;
}