// Общие стили контейнера
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

// Стили для сообщения о загрузке
.loadingMessage {
  font-size: 16px;
  color: #333;
}

// Стили для сообщения об ошибке
.errorMessage {
  color: red;
  font-size: 14px;
  margin-bottom: 15px;
}

// Стили для информации о смене
.shiftInfo {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shiftHeader {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.shiftText {
  margin: 5px 0;
  font-size: 16px;
  color: #333;
}

// Стили для сообщения об отсутствии смен
.noShiftMessage {
  font-size: 16px;
  color: #333;
}

// Стили для кнопок
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
}

.primaryButton {
  background-color: #003b6f;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
}

.secondaryButton {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;

  &:hover {
    background-color: #e2e6ea;
  }
}

// Стили для модального окна
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.modalHeader {
  margin: 0 0 15px;
  font-size: 18px;
  color: #333;
}

.modalText {
  font-size: 16px;
  color: #333;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .button {
    flex: 1;
    margin: 0 5px;
  }
}