$primary-color: #003b6f;
$secondary-color: #ffffff;
$text-color: #333;
$border-color: #606264;

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background-color: $secondary-color;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 0 auto;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: $text-color;
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.controls {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.dropdown,
.dateInput {
  margin-right: 20px;
  width: 30%;
  padding: 1rem 1rem;
  font-size: 1rem;
  border: 2px solid $border-color;
  border-radius: 8px;
  background-color: #fff;
  color: $text-color;
  transition: border-color 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:focus,
  &:hover {
    border-color: $primary-color;
    box-shadow: 0 0 6px rgba(0, 59, 111, 0.5);
  }
}

.loadingMessage,
.errorMessage {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.errorMessage {
  color: red;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.tableHeader {
  text-align: left;
  padding: 1rem;
  border: 1px solid $border-color;
  background-color: $primary-color;
  color: #fff;
  font-weight: bold;
}

.tableRow {
  &:nth-child(even) {
    background-color: lighten($secondary-color, 5%);
  }
}

.tableCell {
  text-align: left;
  padding: 1rem;
  border: 1px solid $border-color;
  color: $text-color;
}


.exportButton {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
}